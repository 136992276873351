(function () {
  // REF: https://davidwalsh.name/javascript-debounce-function
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  // REF: http://www.javascriptkit.com/javatutors/touchevents2.shtml
  function swipedetect(el, callback) {
    var touchsurface = el,
      swipedir,
      startX,
      startY,
      distX,
      distY,
      threshold = 150, //required min distance traveled to be considered swipe
      restraint = 100, // maximum distance allowed at the same time in perpendicular direction
      allowedTime = 300, // maximum time allowed to travel that distance
      elapsedTime,
      startTime,
      handleswipe = callback || function (swipedir) {}

    touchsurface.addEventListener('touchstart', function (e) {
      var touchobj = e.changedTouches[0]
      swipedir = 'none'
      distX = 0
      distY = 0
      startX = touchobj.pageX
      startY = touchobj.pageY
      startTime = new Date().getTime() // record time when finger first makes contact with surface
      e.preventDefault()
    }, false)

    touchsurface.addEventListener('touchmove', function (e) {
      e.preventDefault() // prevent scrolling when inside DIV
    }, false)

    touchsurface.addEventListener('touchend', function (e) {
      var touchobj = e.changedTouches[0]
      distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
      distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
      elapsedTime = new Date().getTime() - startTime // get time elapsed
      if (elapsedTime <= allowedTime) { // first condition for awipe met
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) { // 2nd condition for horizontal swipe met
          swipedir = (distX < 0) ? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
        } else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) { // 2nd condition for vertical swipe met
          swipedir = (distY < 0) ? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
        }
      }
      handleswipe(swipedir)
      e.preventDefault()
    }, false)
  }

  document.querySelectorAll('.image-slider').forEach(function (slider) {
    // remove spaces between elements
    slider.innerHTML = slider.innerHTML.replace(/>\s+</g, '><');

    const centerFrame = slider.querySelector('.image-slider__center-frame');
    const slides = slider.querySelectorAll('.image-slider__slide');
    const prevArrow = slider.querySelector('.image-slider__arrow--prev');
    const nextArrow = slider.querySelector('.image-slider__arrow--next');
    let activeSlide = 0;

    if (!centerFrame || slides.length === 0) return;

    var doPositioning = debounce(function () {
      // CSS has translateY -50% so no need to care about the button height
      const arrowTop = (slides[activeSlide].querySelector('img') || slides[activeSlide]).offsetHeight / 2;
      prevArrow.style.top = `${arrowTop}px`;
      nextArrow.style.top = `${arrowTop}px`;

      prevArrow.style.display = (activeSlide == 0 ? 'none' : '');
      nextArrow.style.display = (activeSlide == slides.length - 1 ? 'none' : '');

      slides.forEach((slide) => {
        slide.classList.remove('active');
      });
      slides[activeSlide].classList.add('active');

      centerFrame.style.transform = `translate(-${slides[activeSlide].offsetLeft}px, 0)`;

    }, 50);

    function prevPage() {
      if (activeSlide === 0) return;
      activeSlide--;
      doPositioning();
    }

    function nextPage() {
      if (activeSlide === slides.length - 1) return;
      activeSlide++;
      doPositioning();
    }

    prevArrow.addEventListener('click', prevPage);
    nextArrow.addEventListener('click', nextPage);

    swipedetect(centerFrame, (swipedir) => {
      if (swipedir === 'left') {
        nextPage();
      } else if (swipedir === 'right') {
        prevPage();
      }
    })

    doPositioning();
    window.addEventListener('resize', doPositioning);
    slider.querySelectorAll('img').forEach((img) => {
      img.addEventListener('load', doPositioning);
    });

  });
})();