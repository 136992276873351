(function () {

  function sortSelect(selElem) {
      var tmpAry = new Array();
      for (var i=0;i<selElem.options.length;i++) {
          tmpAry[i] = new Array();
          tmpAry[i][0] = selElem.options[i].text;
          tmpAry[i][1] = selElem.options[i].value;
      }
      tmpAry.sort();
      while (selElem.options.length > 0) {
          selElem.options[0] = null;
      }
      for (var i=0;i<tmpAry.length;i++) {
          var op = new Option(tmpAry[i][0], tmpAry[i][1]);
          selElem.options[i] = op;
      }
      return;
  }


  document.querySelectorAll('[data-filter-for]').forEach(function (trifilter) {
    const tableId = trifilter.dataset.filterFor;
    const targetList = document.getElementById(tableId);

    if (!targetList) return;

    const noResultMsg = targetList.querySelector(`[data-no-result-message]`);
    const theList = targetList.querySelector(`[data-the-list]`);
    const displayedCounter = targetList.querySelector('[data-list-displayed]');
    const totalCounter = targetList.querySelector('[data-list-total]');
    const moreButton = targetList.querySelector('[data-list-show-more-btn]');
    const pageSize = parseInt(targetList.dataset.pageSize) || 10;
    const alwaysShowVw = 1024;
    let displayedCount;
    let matchCount;

    function applyFilter() {
      let selectedDimension = []; // array of guids
      let isOdd = true; // flip flop tracking the class to apply
      matchCount = 0;

      trifilter.querySelectorAll('select').forEach(function (select) {
        // placeholder option (e.g. All Categories) has an empty value
        if (select.value) selectedDimension.push(select.value);
      });

      theList.querySelectorAll('[data-dimensions]').forEach(function (row) {
        let isMatch = selectedDimension.every((dimension) => {
          let rowDimensions = row.dataset.dimensions.split('|');
          let isMatched = rowDimensions.includes(dimension);
          return isMatched;
        });

        row.classList.remove('odd');
        row.classList.remove('even');
        row.classList.remove('match');
        row.classList.remove('mismatch');

        if (isMatch) {
          row.classList.add('match');
          row.classList.add(isOdd ? 'odd' : 'even');
          isOdd = !isOdd;
          matchCount++;
        } else {
          row.classList.add('mismatch');
        }
      });

      if (matchCount > 0) {
        theList.style.display = '';
        if (noResultMsg) {
          noResultMsg.style.display = 'none';
        }
      } else {
        theList.style.display = 'none';
        if (noResultMsg) {
          noResultMsg.style.display = '';
        }
      }
      trifilter.querySelectorAll('select').forEach(function (select) {
        for (var i=0;i<select.options.length;i++) {
          if(select.options[i].value) {
            var matchedLength = theList.querySelectorAll('[data-dimensions*="|' + select.options[i].value + '|"].match').length;
            if(matchedLength > 0) {
              var liElems = document.querySelectorAll('[data-selectedvalue="' + select.options[i].value + '"]');
              if(liElems.length > 0){
                liElems.forEach(function(liElem){
                  liElem.classList.remove('no-match');
                });
              }
            }else{
              var liElems = document.querySelectorAll('[data-selectedvalue="' + select.options[i].value + '"]');
              if(liElems.length > 0){
                liElems.forEach(function(liElem){
                  liElem.classList.add('no-match');
                });
              }
            }
          }
        }
      });

      let params = Qs.parse(location.hash.substring(1));
      if (selectedDimension.length == 0) {
        delete params[`filter-${tableId}`];
      } else {
        params[`filter-${tableId}`] = selectedDimension.join(',');
      }
      if(Qs.stringify(params) !== ''){
        history.replaceState(null, null, '#' + Qs.stringify(params));
      }else{
        history.replaceState(null, null, '');
      }

      displayedCount = pageSize;
      applyPagination();
    }

    function applyPagination() {
      if (window.innerWidth >= alwaysShowVw) {
        // desktop: always show all
        moreButton.style.display = 'none';
        theList.style.maxHeight = theList.querySelector('.match') ?
          theList.querySelector('.match').clientHeight * pageSize + 'px' :
          '';
        theList.style.overflow = 'auto';

        theList.querySelectorAll('.match').forEach(function (item, i) {
          item.style.display = ''; // undo display: none from mobile
        });

      } else {
        // mobile: click to show more
        displayedCounter.innerHTML = displayedCount;
        totalCounter.innerHTML = matchCount;

        moreButton.style.display = (displayedCount < matchCount) ? '' : 'none';
        theList.style.maxHeight = '';
        theList.style.overflow = '';

        theList.querySelectorAll('.match').forEach(function (item, i) {
          item.style.display = (i < displayedCount) ? '' : 'none';
        });
      }
    }

    // load filter params
    const paramsOnLoad = Qs.parse(location.hash.substring(1));
    if (paramsOnLoad[`filter-${tableId}`]) {
      const preappliedDimensions = paramsOnLoad[`filter-${tableId}`].split(',');
      preappliedDimensions.forEach((dimension) => {
        const option = trifilter.querySelector(`option[value="${dimension}"]`);
        if (option) option.selected = true;
      });
    }

    // apply default processing
    applyFilter();
    applyPagination();

    // event handlers
    trifilter.querySelectorAll('select').forEach(function (select, index) {
      select.addEventListener('change', applyFilter);
    });

    moreButton.addEventListener('click', function () {
      displayedCount += pageSize;
      applyPagination();
    });

    window.addEventListener('resize', applyPagination);

  });

  function initSelect(el){
    var select = el.querySelector('select');
    var dropdownHtml = '<div class="trifilter__dropdown-wrapper">';
    dropdownHtml += '<div class="trifilter__dropdown-selected">';
    dropdownHtml += select[select.selectedIndex].text;
    dropdownHtml += '</div>';
    dropdownHtml += '<div class="trifilter__dropdown-toggle">';
    dropdownHtml += '<div class="trifilter__dropdown-search-wrapper">';
    dropdownHtml += '<input type="text" class="trifilter__dropdown-search" placeholder="Search..." />';
    dropdownHtml += '</div>';
    dropdownHtml += '<ul>';
    for(var i = 0 ; i < select.children.length; i++){
      switch(select.children[i].tagName){
        case 'OPTGROUP':
          dropdownHtml += '<li class="optgroup-wrapper">';
          dropdownHtml += '<span class="optgroup-title">' + select.children[i].label + '</span>';
          var subchildren = select.children[i];
          if(subchildren.children.length > 0){
            dropdownHtml += '<ul>';
            for(var j = 0; j < subchildren.children.length; j++){
              var matchedLength = document.querySelectorAll('[data-dimensions*="|' + subchildren.children[j].value + '|"].match').length;
              dropdownHtml += '<li data-selectedindex="' + subchildren.children[j].index + '" data-selectedvalue="' + subchildren.children[j].value + '" class="';
              if(matchedLength === 0 && subchildren.children[j].value) dropdownHtml += 'no-match';
              dropdownHtml += '"><span class="option-title">' + subchildren.children[j].text + '</span></li>';
            }
            dropdownHtml += '</ul>';
          }
          dropdownHtml += '</li>';
          break;
        case 'OPTION':
          var matchedLength = document.querySelectorAll('[data-dimensions*="|' + select.children[i].value + '|"].match').length;
          dropdownHtml += '<li data-selectedindex="' + select.children[i].index + '" data-selectedvalue="' + select.children[i].value + '" class="';
          if(matchedLength === 0 && select.children[i].value) dropdownHtml += 'no-match';
          dropdownHtml += '"><span class="option-title">' + select.children[i].text + '</span></li>';
          break;
      }
    }
    dropdownHtml += '</ul>';
    dropdownHtml += '</div>';
    dropdownHtml += '</div>';
    el.classList.add("dropdown-select-inited");
    el.innerHTML += dropdownHtml;

    el.querySelector('.trifilter__dropdown-selected').addEventListener('click', function(e){
      el.classList.toggle('trifilter__dropdown-select-opened');
    });

    el.querySelector('.trifilter__dropdown-search').addEventListener('keyup', function(e){
      var searchString = e.target.value;
      searchString = searchString.trim();
      el.querySelectorAll('.optgroup-wrapper').forEach(function(item, i){
        item.classList.remove('hidden');
      });
      if(searchString === ''){
        el.querySelectorAll('.option-title').forEach(function (item, i) {
          item.classList.remove('hidden');
        });
      }else{
        searchString = searchString.toLowerCase();
        searchString = searchString.replace(/\s\s+/g, ' ');
        searchString = searchString.split(' ');
        el.querySelectorAll('.option-title').forEach(function (item, i) {
          var hit = false;
          for(var k = 0; k < searchString.length; k++){
            if(item.innerText.toLowerCase().indexOf(searchString[k]) !== -1){
              hit = true;
              break;
            }
          }
          if(hit){
            item.classList.remove('hidden');
          }else{
            item.classList.add('hidden');
          }
        });
        el.querySelectorAll('.optgroup-wrapper').forEach(function(item, i){
          var hiddenCount = item.querySelectorAll('.option-title.hidden').length;
          var normalCount = item.querySelectorAll('.option-title').length;
          if(normalCount === hiddenCount){
            item.classList.add('hidden');
          }else{
            item.classList.remove('hidden');
          }
        });
      }
    });

    el.querySelectorAll('.option-title').forEach(function (item, i) {
      item.addEventListener('click', function(e){
        if(item.parentNode.classList.contains('no-match')) return;
        try{
          el.querySelector('select').value = item.parentNode.dataset.selectedvalue;
          el.querySelector('select').selectedIndex = item.parentNode.dataset.selectedindex;
          select.dispatchEvent(new Event('change'));
        }catch(e){
          console.log(e);
        }
        el.classList.toggle('trifilter__dropdown-select-opened');
        el.querySelector('.trifilter__dropdown-selected').innerHTML = item.innerText;
      });
    });
  }

  function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }

  if(!isTouchDevice()){
    var selects = document.getElementsByClassName('trifilter__group');
    if (selects.length === 0) return;
    var possibleWidth = 100 / selects.length;
    possibleWidth = possibleWidth.toFixed(2);
    for(var i = 0; i < selects.length; i++){
        initSelect(selects[i]);
        selects[i].style.width = possibleWidth + '%';
    }
    document.body.addEventListener('click', function(e){
      try{
        if(!e.target.classList.contains('trifilter__dropdown-selected') && !e.target.classList.contains('trifilter__dropdown-search')){
          document.querySelectorAll('.trifilter__dropdown-select-opened').forEach(function(item, i){
            item.classList.remove('trifilter__dropdown-select-opened');
          });
        }
      }catch(e){
        console.log(e);
      }
    });
  }

})();