(function () {
  // --- Hamburger Menu --- //
  var hamburgerButton = document.getElementById('hamburger-button');
  var hamburger = document.getElementById('hamburger');

  hamburgerButton.addEventListener('click', (ev) => {
    hamburgerButton.classList.toggle('closed');
    hamburger.classList.toggle('closed');
  });

  // --- Video Popup --- //
  const videoPopup = document.getElementById('video-popup');
  const videoPopupIframe = document.getElementById('video-popup-iframe');

  document.querySelectorAll('[data-youtube-popup-id]').forEach(function (elem) {
    elem.addEventListener('click', function (ev) {
      ev.preventDefault();
      const youtubeId = this.dataset.youtubePopupId;
      videoPopup.classList.add('show');
      videoPopupIframe.src = `https://www.youtube.com/embed/${youtubeId}`;
    });
  });

  videoPopup.addEventListener('click', function (ev) {
    if (ev.target !== videoPopupIframe) {
      videoPopup.classList.remove('show');
      videoPopupIframe.src = '';
    }
  });

  // --- Smooth Scroll function --- //
  // REF: https://gist.github.com/andjosh/6764939#gistcomment-2553779
  function smoothScrollTo(element, to = 0, duration = 600) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = (() => {
      currentTime += increment;
      const val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    });

    animateScroll();
  };

  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  // --- Footer Controls --- //
  document.getElementById('back-to-top').addEventListener('click', function (ev) {
    ev.preventDefault();
    smoothScrollTo(document.documentElement);
  });

  document.getElementById('back-to-prev').addEventListener('click', function (ev) {
    ev.preventDefault();
    history.go(-1);
  });

})();